<template>
  <Title :text="t(`t1`)" />
  <div class="grid-column grid-column_span_5">
    <Promo
      class="promo_full_width"
      :src-pic="require(`@/assets/cover/education.jpg`)"
      :src-pic-mobile="require(`@/assets/cover/m/education.jpg`)"
    />
  </div>

  <div class="grid-column">
    <Share :title="t(`t1`)" />
  </div>
  <div class="grid-column grid-column_span_4 grid-column_row_2">
    <div class="h2-1" v-html="t(`t2`)"></div>
  </div>

  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_span_row_3"></div>
  <div class="grid-column grid-column_span_2">
    <Preview :image="require('@/assets/education/1.jpg')">
      <div class="preview-grid">
        <div class="h3" v-html="t(`t3`)"></div>
        <div v-html="t(`t4`)"></div>
      </div>
    </Preview>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_span_2">
    <Preview :image="require('@/assets/education/2.jpg')">
      <div class="preview-grid">
        <div class="h3" v-html="t(`t5`)"></div>
        <div v-html="t(`t6`)"></div>
      </div>
    </Preview>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_span_2">
    <Preview :image="require('@/assets/education/3.jpg')">
      <div class="preview-grid">
        <div class="h3" v-html="t(`t7`)"></div>
        <div v-html="t(`t8`)"></div>
      </div>
    </Preview>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_span_2">
    <Preview :image="require('@/assets/education/4.jpg')">
      <div class="preview-grid">
        <div class="h3" v-html="t(`t9`)"></div>
        <div v-html="t(`t10`)"></div>
      </div>
    </Preview>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_span_2">
    <Preview :image="require('@/assets/education/5.jpg')">
      <div class="preview-grid">
        <div class="h3" v-html="t(`t11`)"></div>
        <div v-html="t(`t12`)"></div>
      </div>
    </Preview>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_span_2">
    <Preview :image="require('@/assets/education/6.jpg')">
      <div class="preview-grid">
        <div class="h3" v-html="t(`t13`)"></div>
        <div v-html="t(`t14`)"></div>
      </div>
    </Preview>
  </div>

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>

  <div v-if="isMobile" class="grid-line"></div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import Title from "@/components/Title.vue";
import Promo from "@/components/Promo.vue";
import Share from "@/components/Share.vue";
import News from "@/components/News.vue";
import Preview from "@/components/Preview.vue";

export default defineComponent({
  name: "Education",
  components: {
    Title,
    Promo,
    Share,
    News,
    Preview,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },
});
</script>

<style lang="scss" scoped>
.preview-grid {
  display: grid;
  grid-auto-flow: column;
  gap: val(48);
  grid-auto-columns: 1fr 1fr;
  padding-top: val(18);
  @include mobile {
    display: block;
    padding-top: valM(18);
  }
  .h3 {
    @include mobile {
      font-size: valM(24);
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 133%;
    @include mobile {
      margin-top: valM(18);
      font-size: valM(15);
    }
    li {
      position: relative;
      @include mobile {
        padding-left: valM(24);
      }
      &::before {
        position: absolute;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6h9M10 1l5 4.995L10 11' stroke='%233C3C3B' stroke-miterlimit='10'/%3E%3C/svg%3E");
        width: val(16);
        height: val(12);
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
        top: val(4);
        left: val(-32);
        @include mobile {
          width: valM(15);
          height: valM(10);
          top: valM(5);
          left: 0;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "t1": "Education",
    "t2": "<p>In the education area the main focus of the Office is on increasing the intensity of educational inter-country collaborations to improve, create and offer new collaboration formats.</p><p>The Office supports a wide scope of collaboration activities: programs between education institutions, students, visiting professors and exchange programs, research projects, summer schools, bootcamps, calls for proposals and many others.</p>",
    "t3": "UNIVERSITIES AND GOVERNMENT RELATIONS",
    "t4": "<ul><li>MoUs, Cooperation Agreements</li><li>Universities and academic visits and business missions</li><li>Reciprocal increase of quotas for international students</li><li>Accreditation of the Russian Universities by the Qatar Ministry of Education and Higher Education</li></ul>",
    "t5": "EDUCATION",
    "t6": "<ul><li>Student exchange programs</li><li>Student visiting programs</li><li>Short-term visiting program (2 weeks, 1 month) for Russian students to Qatar and Qatari students to Russia</li><li>Visiting professors programs</li><li>Lecture course from Russian professor for Qatari students (General public, students of all the universities), several days or 1-2 weeks</li><li>Open lectures and events</li></ul>",
    "t7": "ACADEMIC COLLABORATIONS",
    "t8": "<ul><li>Double diplomas</li><li>Joint programs</li><li>Long-term exchange program (semester, year) for the Russian students to Qatar and the Qatari students to Russia</li></ul>",
    "t9": "CALL FOR PROPOSALS AND ONLINE CHALLENGES",
    "t10": "<ul><li>Information about special activities</li><li>calls</li><li>challenges</li></ul>",
    "t11": "SUMMER SCHOOLS AND BOOTCAMPS",
    "t12": "<ul><li>Information about summer schools</li><li>Meet-ups with students of high-schools and universities of Russia to introduce them to Qatari educational programs</li><li>Promotional tours to Qatar educational institutions</li></ul>",
    "t13": "SPORTS AND VOLUNTEER EDUCATION INITIATIVES",
    "t14": "<ul><li>Edu program for children and the general public</li></ul>",
  },
  "ru": {
    "t1": "Образование",
    "t2": "<p>Основной задачей OKI в сфере образования является расширение международного образовательного сотрудничества, разработка предложений, создание и развитие новых форм сотрудничества.</p><p>OKI реализует широкий спектр программ международного сотрудничества: совместные образовательные программы, программы обмена для профессоров и студентов, исследовательские проекты, летние школы, учебные курсы, конкурсы проектов и многое другое.</p>",
    "t3": "ВЗАИМОДЕЙСТВИЕ С ВУЗАМИ И ГОСУДАРСТВЕННЫМИ ОРГАНИЗАЦИЯМИ",
    "t4": "<ul><li>Меморандумы о взаимопонимании, соглашения о сотрудничестве</li><li>Академические визиты и бизнес-миссии</li><li>Взаимное увеличение квоты для обучения иностранных студентов</li><li>Аккредитация российских университетов Министерством образования Катара</li></ul>",
    "t5": "ОБРАЗОВАНИЕ",
    "t6": "<ul><li>Программы студенческого обмена</li><li>Стажировки для студентов</li><li>Краткосрочные образовательные программы (2 недели – 1 месяц) для российских студентов в Катаре и катарских студентов в России</li><li>Стажировки для профессорско-преподавательского состава</li><li>Курс лекций российского профессора для катарских студентов (широкая публика, студенты всех университетов). Курс рассчитан на несколько дней или 1-2 недели</li><li>Открытые лекции и мероприятия</li></ul>",
    "t7": "АКАДЕМИЧЕСКОЕ СОТРУДНИЧЕСТВО",
    "t8": "<ul><li>Программы двойных дипломов</li><li>Совместные программы</li><li>Долгосрочные программы обмена (семестр, год) для студентов из России в Катар и катарских студентов в Россию</li></ul>",
    "t9": "КОНКУРС ПРОЕКТОВ И ОНЛАЙН-КОНКУРСЫ",
    "t10": "<ul><li>Информация о специальных мероприятиях</li><li>конкурсы</li></ul>",
    "t11": "ЛЕТНИЕ ШКОЛЫ И УЧЕБНЫЕ КУРСЫ",
    "t12": "<ul><li>Информация о летних школах</li><li>Презентации образовательных программ катарских университетов для российских школьников и студентов</li><li>Туры в учебные заведения Катара</li></ul>",
    "t13": "СПОРТИВНЫЕ И ВОЛОНТЕРСКИЕ ОБРАЗОВАТЕЛЬНЫЕ ИНИЦИАТИВЫ",
    "t14": "<ul><li>Образовательная программа для детей и широкой публики</li></ul>",
  },
  "ar": {
    "t1": "التعليم",
    "t2": "تتمثل المهمة الرئيسية لمكتب المعرفة والابتكار في مجال التعليم في توسيع التعاون التعليمي الدولي، وتطوير المقترحات، وإنشاء وتطوير أشكال جديدة من التعاون. ينفذ مكتب المعرفة والابتكار مجموعة واسعة من برامج التعاون الدولي: البرامج التعليمية المشتركة، وبرامج التبادل للأساتذة والطلاب، والمشاريع البحثية، والمدارس الصيفية، والدورات التدريبية، ومسابقات المشاريع وغير ذلك الكثير.",
    "t3": "التفاعل مع الجامعات والمنظمات الحكومية",
    "t4": "<ul><li>مذكرات تفاهم واتفاقيات تعاون</li><li>الزيارات الأكاديمية والبعثات التجارية</li><li>الزيارات المتبادلة في الحصة المخصصة لتدريب الطلاب الأجانب</li><li>اعتماد الجامعات الروسية من قبل وزارة التعليم والتعليم العالي في قطر</li></ul>",
    "t5": "التعليم",
    "t6": "<ul><li>برامج التبادل الطلابي</li><li>التدريب الداخلي للطلاب</li><li>برامج تعليمية قصيرة المدى (أسبوعين - شهر واحد) للطلاب الروس في قطر والطلاب القطريين في روسيا</li><li>تدريب لأعضاء هيئة التدريس</li><li>دورة محاضرات لأستاذ روسي للطلاب القطريين (عامة الناس ، طلاب جميع الجامعات). الدورة مصممة لعدة أيام أو من أسبوع إلى أسبوعين</li><li>فتح محاضرات وفعاليات</li></ul>",
    "t7": "التعاون الأكاديمي",
    "t8": "<ul><li>برامج درجة مزدوجة</li><li>البرامج المشتركة</li><li>برامج التبادل طويلة الأجل (فصل دراسي، سنة دراسية) للطلاب من روسيا إلى قطر والطلاب القطريين إلى روسيا</li></ul>",
    "t9": "مسابقة المشاريع والمسابقات عبر الإنترنت",
    "t10": "<ul><li>معلومات حول الأحداث الخاصة</li><li>مسابقات</li></ul>",
    "t11": "المدارس الصيفية والدورات التدريبية",
    "t12": "<ul><li>معلومات عن المدارس الصيفية</li><li>عروض البرامج التعليمية للجامعات القطرية لأطفال المدارس والطلاب الروس</li><li>جولات للمؤسسات التعليمية في قطر</li><li>معلومات عن المدارس الصيفية</li><li>عروض البرامج التعليمية للجامعات القطرية لأطفال المدارس والطلاب الروس</li><li>جولات للمؤسسات التعليمية في قطر</li></ul>",
    "t13": "المبادرات الرياضية وتعليم المتطوعين",
    "t14": "<ul><li>برنامج تعليمي للأطفال وعامة الجمهور</li></ul>",
  }
}
</i18n>
