<template>
  <router-link v-if="link" :to="link" class="preview grid-inside">
    <div class="preview__body">
      <div class="preview__pic">
        <img :src="image" alt="" />
      </div>
      <div class="preview__content">
        <h3 class="h3 preview__title upper" v-html="title"></h3>
        <p class="preview__desc" v-html="desc"></p>
      </div>
    </div>
    <div class="preview__foot">
      <Button class="btn-default-grey" :msg="t('common_more')" />
    </div>
  </router-link>
  <div v-else class="preview grid-inside">
    <div class="preview__body">
      <div class="preview__pic">
        <img :src="image" alt="" />
      </div>
      <div class="preview__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import Button from "@/components/Button.vue";

export default defineComponent({
  name: "Preview",
  components: {
    Button,
  },
  props: {
    title: String,
    desc: String,
    image: String,
    link: {
      type: [String, Object],
      default: "",
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
});
</script>

<style scoped lang="scss">
.preview {
  text-decoration: none !important;
  &__title {
    overflow: hidden;
    margin-top: val(16);
    font-weight: bold;
    word-break: break-word;
    @include mobile {
      margin-bottom: valM(18);
    }
  }
  &__desc {
    margin-top: val(9);
    word-break: break-word;
    @include mobile {
      margin-bottom: valM(18);
    }
    margin-bottom: val(14);
  }
  &__pic {
    img {
      width: 100%;
    }
  }
  &__foot {
    margin-top: val(30);
  }
  &-grid {
    display: none;
  }
}
</style>
